export const LIST_OF_ARTICLES = [
  {
    site: "kapital.kz",
    publicationDate: "17.02.2023",
    titleOfArticle: "THIRD_OFFICE_OPENED_CAPITAL",
    img: "/img/massMediaArticles/kapital-HanShatyr.jpg",
    linkToArticle:
      "https://kapital.kz/business/113139/tretiy-ofis-kaztour-otkryli-v-stolichnom-trts-khan-shatyr.html",
  },
  {
    site: "uralskweek.kz",
    publicationDate: "10.01.2023",
    titleOfArticle: "NATIONAL_TRAVEL_AGENCY_NOW_IN",
    img: "/img/massMediaArticles/uralskweek-KaztourInUralsk.jpg",
    linkToArticle:
      "https://www.uralskweek.kz/2023/01/10/nacionalnoe-turagentstvo-kaztour-teper-v-uralske/",
  },
  {
    site: "forbes.kz",
    publicationDate: "28.07.2021",
    img: "/img/massMediaArticles/forbes-Teachers.jpg",
    titleOfArticle: "POWER_OF_TRAVEL_IMPORTANT_RECHARGE_FOR_TEACHERS",
    linkToArticle:
      "https://forbes.kz/articles/kak_kaztour_ustroil_shkolnyim_uchitelyam_otdyih_na_krasnom_more/",
  },
  {
    site: "forbes.kz",
    publicationDate: "08.07.2021",
    img: "/img/massMediaArticles/forbes-TwentyYearsKaztour.jpg",
    linkToArticle: "https://forbes.kz/articles/bolshaya_missiya/",
    titleOfArticle: "ALREADY_YEARS_MAKES_KAZAKHSTANIS_FALL_IN_LOVE_WITH_TRAVEL",
  },
  {
    site: "forbes.kz",
    publicationDate: "25.01.2023",
    img: "/img/massMediaArticles/forbes-SuccessfulBusiness.jpg",
    titleOfArticle: "HOW_TO_BUILD_SUCCESSFUL_TRAVEL_BUSINESS_QUARTER_CENTURY",
    linkToArticle:
      "https://forbes.kz/articles/kak_postroit_uspeshnyiy_turbiznes_kotoromu_uje_pochti_chetvert_veka/",
  },
  {
    site: "the-village-kz.com",
    publicationDate: "25.01.2023",
    titleOfArticle: "JOURNEY_LASTING_YEARS",
    img: "/img/massMediaArticles/village-FamilyValues.png",
    linkToArticle:
      "https://www.the-village-kz.com/village/business/specials-business/28573-kaztour-family-matters",
  },
  {
    site: "forbes.kz",
    publicationDate: "13.06.2023",
    img: "/img/massMediaArticles/forbes-siteAndApp.jpg",
    titleOfArticle: "DISCOVER_MAGIC_OF_TRAVEL_WITH_NEW_SITE_AND_APP",
    linkToArticle:
      "https://forbes.kz/articles/otkroyte_dlya_sebya_magiyu_puteshestviy_s_novyimi_saytom_i_prilojeniem_kaztour/",
  },
  {
    site: "top-news.kz",
    publicationDate: "31.01.2023",
    titleOfArticle: "OFFICE_LARGEST_TRAVEL_AGENCY",
    img: "/img/massMediaArticles/top-news-officeInKostanay.jpg",
    linkToArticle:
      "https://top-news.kz/ofis-krupnejshego-turagentstva-kazahstana-kaztour-otkrylsja-v-kostanae/",
  },
  {
    site: "tourcode.kz",
    publicationDate: "23.02.2023",
    titleOfArticle: "THIRD_OFFICE_OPENED_CAPITAL",
    img: "/img/massMediaArticles/tourcode-HanShatyr.jpg",
    linkToArticle:
      "https://tourcode.kz/turistam/novost/tretij-ofis-kaztour-otkrylsya-v-stolichnom-trc-han-shatyr",
  },
  {
    site: "lada.kz",
    publicationDate: "17.03.2023",
    titleOfArticle: "LARGEST_TRAVEL_AGENCY",
    img: "/img/massMediaArticles/lada-newOfficeInAktau.jpg",
    linkToArticle:
      "https://www.lada.kz/partnerskiy-proekt/109410-krupneyshee-turagentstvo-kazahstana-otkrylo-svoy-ofis-v-aktau.html",
  },
  {
    site: "kstnews.kz",
    publicationDate: "01.02.2023",
    titleOfArticle: "OFFICE_LARGEST_TRAVEL_AGENCY",
    linkToArticle: "https://kstnews.kz/news/society/item-77578",
    img: "/img/massMediaArticles/kstnews-newOfficeInKostanay.jpg",
  },
  {
    site: "weproject.media",
    publicationDate: "17.02.2023",
    titleOfArticle: "THIRD_OFFICE_OPENED_CAPITAL",
    img: "/img/massMediaArticles/weproject-HanShatyr.jpg",
    linkToArticle:
      "https://weproject.media/articles/detail/tretiy-ofis-kaztour-otkryt-v-stolichnom-trts-khan-shatyr/",
  },
  {
    site: "mgorod.kz",
    publicationDate: "17.03.2023",
    img: "/img/massMediaArticles/mgorod-newOfficeInAktau.jpg",
    titleOfArticle: "REPRESENTATIVE_OFFICE_LARGEST_TRAVEL_AGENCY",
    linkToArticle:
      "https://mgorod.kz/nitem/predstavitelstvo-krupnejshego-turisticheskogo-agentstva-kazaxstana-otkrylos-v-gorode-aktau/",
  },
  {
    site: "alau.kz",
    publicationDate: "01.02.2023",
    img: "/img/massMediaArticles/alau-newOfficeInKostanay.jpg",
    titleOfArticle: "NOW_KOSTANAY_RESIDENTS_CAN_BOOK_TOUR_THROUGH_COMPANY",
    linkToArticle:
      "https://alau.kz/teper-u-kostanajcev-est-vozmozhnost-zabronirovat-tur-v-ljubuju-tochku-mira-cherez-kompaniju-kaztour/",
  },
  {
    site: "inaktau.kz",
    publicationDate: "17.03.2023",
    img: "/img/massMediaArticles/inaktau-newOfficeInAktau.jpg",
    titleOfArticle: "REPRESENTATIVE_OFFICE_LARGEST_TRAVEL_AGENCY",
    linkToArticle:
      "https://www.inaktau.kz/news/3565048/predstavitelstvo-krupnejsego-turisticeskogo-agentstva-kazahstana-kaztour-otkrylos-v-aktau",
  },
];
