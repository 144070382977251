"use client";
import { FC } from "react";

import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { ListLayout } from "@/entities/constructor/list-layout";

import { sendAmplitudeEvent } from "@/shared/model/analytics";
import { getArticlesPageQuery } from "@/shared/model/api/constructor";
import { ConstructorCard, ConstructorCardList } from "@/shared/ui";

const SLICE_AMOUNT = 4;

export const ArticlesList: FC = () => {
  const t = useTranslations();
  const { data: articles, pending: isLoading } = useUnit(getArticlesPageQuery);
  const sendAmplitudeEventFn = useUnit(sendAmplitudeEvent);

  if ((!articles && !isLoading) || articles?.items.length === 0) {
    return <></>;
  }

  const slicedList = articles?.items.slice(0, SLICE_AMOUNT) ?? [];

  return (
    <ListLayout
      href="/articles"
      title={t("ARTICLES")}
      linkText={t("GO_TO_ARTICLES")}
      subtitle={t("MOST_USEFUL_THINGS")}
      total={articles?.pagination.total ?? 0}
    >
      <ConstructorCardList isLoading={isLoading} listLength={slicedList.length}>
        {slicedList.map((c) => (
          <ConstructorCard
            page={c}
            key={c.id}
            onClick={() =>
              sendAmplitudeEventFn({
                event: "constructorArticle",
                data: {
                  articleTopic: c.name,
                },
              })
            }
          />
        ))}
      </ConstructorCardList>
    </ListLayout>
  );
};
