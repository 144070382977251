"use client";

import { FC } from "react";

import { Box, Container, useMediaQuery } from "@mui/material";

import { styles } from "./styles";

import {
  MobileAppBlock,
  DownloadAppBlock,
} from "@/entities/app-downloading-block";

import { useCustomTheme } from "@/shared/lib/hooks";

export const DownloadApp: FC = () => {
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));

  if (!isPhone) {
    return (
      <Container sx={styles.downloadAppContainer}>
        <DownloadAppBlock />
      </Container>
    );
  }

  return (
    <Container sx={styles.container}>
      <Box sx={styles.appDownload}>
        <MobileAppBlock />
      </Box>
    </Container>
  );
};
