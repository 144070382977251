"use client";
import Image from "next/image";
import { FC } from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";
import { useTranslations } from "next-intl";
import "swiper/css";
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, useSwiper, SwiperSlide } from "swiper/react";

import { styles } from "./styles";

import { PARTNERS_LIST } from "@/entities/partners/OurPartners/lib/constans";

import { getPartnerImageLink } from "@/shared/lib/helpers";
import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";
import { Container, SliderButton } from "@/shared/ui";

type TSwiperProps = {
  className: string;
};

const SwiperPrevButton = (props: TSwiperProps) => {
  const swiper = useSwiper();
  return (
    <SliderButton arrow="left" {...props} onClick={() => swiper.slidePrev()} />
  );
};
const SwiperNextButton = (props: TSwiperProps) => {
  const swiper = useSwiper();
  return (
    <SliderButton arrow="right" {...props} onClick={() => swiper.slideNext()} />
  );
};

export const OurPartners: FC = () => {
  const t = useTranslations();
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={styles.outerWrapper(isTablet, isPhone)}>
      <Container sx={styles.container}>
        <Box sx={styles.innerWrapper(isPhone)}>
          <Typography component={"h2"} sx={styles.title(isTablet, isPhone)}>
            {t("OUR_PARTNERS")}
          </Typography>
          <Box sx={styles.swiper(isPhone)}>
            <Swiper
              loop={true}
              className="mySwiper"
              modules={[Autoplay, Navigation]}
              slidesPerView={isTablet ? (isPhone ? 1.3 : 2.3) : 4}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              {PARTNERS_LIST.map((partner: string, idx: number) => (
                <SwiperSlide key={idx} className="swiper-slide-custom">
                  {/* TODO change <Box> to <Link> when deciding which links to use */}
                  <Box sx={styles.swiperLink(isTablet, isPhone)}>
                    <Image
                      width={200}
                      height={110}
                      quality={100}
                      alt={`лого ${partner}`}
                      src={getPartnerImageLink(partner)}
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                      }}
                    />
                  </Box>
                </SwiperSlide>
              ))}

              <Box sx={styles.swiperButtons}>
                <SwiperPrevButton className="swiper-button swiper-button-prev" />
                <SwiperNextButton className="swiper-button swiper-button-next" />
              </Box>
            </Swiper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
