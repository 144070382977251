export const PARTNERS_LIST = [
  "AirAstana",
  "FunSun",
  "FlyDubai",
  "Rixos",
  "VisitDubai",
  "RasAlKhaimah",
  "SCAT",
  "TurkishAirlines",
];
