"use client";
import { FC } from "react";

import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";

import { ListLayout } from "@/entities/constructor/list-layout";

import { sendAmplitudeEvent } from "@/shared/model/analytics";
import { getPromoPageQuery } from "@/shared/model/api/constructor";
import { ConstructorCard, ConstructorCardList } from "@/shared/ui";

const SLICE_AMOUNT = 4;

export const PromoList: FC = () => {
  const t = useTranslations();

  const { data: promo, pending: isLoading } = useUnit(getPromoPageQuery);
  const sendAmplitudeEventFn = useUnit(sendAmplitudeEvent);

  if ((!promo && !isLoading) || promo?.items.length === 0) {
    return <></>;
  }

  const slicedList = promo?.items.slice(0, SLICE_AMOUNT) ?? [];

  return (
    <ListLayout
      href="/promo"
      title={t("PROMOTIONS")}
      linkText={t("GO_TO_PROMOTIONS")}
      subtitle={t("PROMO_SUBTITLE_MAIN")}
      total={promo?.pagination.total ?? 0}
    >
      <ConstructorCardList isLoading={isLoading} listLength={slicedList.length}>
        {slicedList.map((c) => (
          <ConstructorCard
            page={c}
            key={c.id}
            onClick={() =>
              sendAmplitudeEventFn({
                event: "specialActions",
                data: { title: c.name },
              })
            }
          />
        ))}
      </ConstructorCardList>
    </ListLayout>
  );
};
