import { FC } from "react";

import { Box, Button, Typography, useMediaQuery } from "@mui/material";

import { styles } from "./styles";

import { useCustomTheme } from "@/shared/lib/hooks";
import { Link, Container } from "@/shared/ui";

type TProps = {
  href: string;
  title: string;
  total: number;
  subtitle: string;
  linkText: string;
  children: JSX.Element;
};

export const ListLayout: FC<TProps> = ({
  href,
  title,
  total,
  subtitle,
  children,
  linkText,
}) => {
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container sx={styles.wrapper}>
      <Box sx={styles.texts}>
        <Box>
          <Typography component="h2" sx={styles.title}>
            {title}
          </Typography>
          <Typography component="h3" sx={styles.subtitle}>
            {subtitle}
          </Typography>
        </Box>

        {!isPhone && (
          <Link href={href}>
            {total > 0 ? `${linkText} · ${total}` : linkText}
          </Link>
        )}
      </Box>

      {children}

      {isPhone && (
        <Box sx={styles.fullWidth}>
          <Link href={href} style={styles.fullWidth}>
            <Button fullWidth component="span" variant="outlined">
              {total > 0 ? `${linkText} · ${total}` : linkText}
            </Button>
          </Link>
        </Box>
      )}
    </Container>
  );
};
