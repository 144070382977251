import * as CSS from "csstype";
import { COLORS } from "shared/ui/styles";

type TStyles = {
  container: CSS.Properties;
  swiperButtons: CSS.Properties;
  innerWrapper: (isTablet: boolean) => CSS.Properties;
  title: (isTablet: boolean, isPhone: boolean) => CSS.Properties;
  swiperLink: (isTablet: boolean, isPhone: boolean) => CSS.Properties;
  outerWrapper: (isTablet: boolean, isPhone: boolean) => CSS.Properties;
  swiper: (isPhone: boolean) => CSS.Properties & {
    "& .mySwiper": CSS.Properties & {
      "& .swiper-wrapper": CSS.Properties;
      "& .swiper-button": CSS.Properties & { "&:hover path": CSS.Properties };
    };
  };
};

export const styles: TStyles = {
  container: {
    padding: "0 16px",
    overflow: "hidden",
  },

  swiperButtons: {
    right: 0,
    top: "48px",
    width: "100%",
    display: "flex",
    maxWidth: "74px",
    columnGap: "12px",
    position: "absolute",
  },

  outerWrapper: (isTablet: boolean, isPhone: boolean) => ({
    marginTop: isTablet ? "48px" : "0",
    marginBottom: isTablet ? "48px" : "80px",
    backgroundColor: isPhone ? "transparent" : "primary.whiteblue",
  }),

  innerWrapper: (isPhone: boolean) => ({
    overflow: "hidden",
    borderRadius: "8px",
    position: "relative",
    padding: isPhone ? "24px" : "36px 0 48px",
    backgroundColor: isPhone ? "#EDF0F2" : "transparent",
  }),

  title: (isTablet: boolean, isPhone: boolean) => ({
    fontWeight: 500,
    textAlign: "center",
    color: "content.grey-400",
    fontSize: isTablet ? "24px" : "32px",
    lineHeight: isTablet ? "28px" : "38px",
    marginBottom: isPhone ? "16px" : "36px",
  }),

  swiperLink: (isTablet: boolean, isPhone: boolean) => ({
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    pointerEvents: "auto",
    justifyContent: "center",
    transition: "filter 0.2s",
    filter: "grayscale(1) opacity(0.6)",
    maxHeight: isPhone ? "92px" : "130px",
    maxWidth: isPhone ? "218px" : "310px",
    padding: isPhone ? "23px 33px" : "10px 55px",
    "&:active": {
      filter: isTablet || isPhone ? "grayscale(0) opacity(1)" : "none",
    },
    "&:hover": {
      transition: "filter 0.2s",
      filter: isTablet || isPhone ? "none" : "grayscale(0) opacity(1)",
    },
  }),

  swiper: (isPhone: boolean) => ({
    "& .mySwiper": {
      width: "100%",
      position: "static",
      overflow: "visible",
      "& .swiper-wrapper": {
        zIndex: 0,
        alignItems: "center",
      },
      "& .swiper-button": {
        padding: 0,
        width: "32px",
        zIndex: "auto",
        height: "32px",
        minWidth: "20px",
        minHeight: "20px",
        borderRadius: "10px",
        alignItems: "center",
        justifyContent: "center",
        transition: "opacity .2s",
        backgroundColor: "secondary.main",
        display: isPhone ? "none" : "flex",

        "&:hover path": {
          stroke: COLORS.BLUE,
        },
      },
    },
  }),
};
