import { mainTheme } from "@/shared/ui/styles";

export const styles = {
  fullWidth: {
    width: "100%",
  },

  wrapper: {
    marginBottom: "80px",
  },

  link: {
    margin: "0 auto",
    maxWidth: "200px",
  },

  texts: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },

  subtitle: {
    fontWeight: 400,
    fontSize: "22px",
    lineHeight: "25px",

    [mainTheme.breakpoints.down("md")]: {
      fontSize: "16px",
      lineHeight: "18px",
    },
  },

  title: {
    fontSize: "32px",
    lineHeight: "38px",
    marginBottom: "12px",

    [mainTheme.breakpoints.down("md")]: {
      fontSize: "20px",
      lineHeight: "24px",
      marginBottom: "8px",
    },
  },
};
