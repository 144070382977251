import * as CSS from "csstype";
import { COLORS } from "shared/ui/styles";
import { mainTheme } from "shared/ui/styles";

export const styles = {
  link: {
    width: "100%",
    height: "100%",
    position: "relative",
    textDecoration: "none",
  } as CSS.Properties,

  title: {
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "38px",
    color: "content.primary",

    [mainTheme.breakpoints.down("md")]: {
      fontSize: "24px",
      lineHeight: "28px",
    },
  },

  swiperButtons: {
    top: 0,
    width: "100%",
    right: "16px",
    display: "flex",
    maxWidth: "74px",
    columnGap: "12px",

    [mainTheme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  wrapper: {
    top: 0,
    width: "97%",
    display: "flex",
    alignItems: "center",
    marginBottom: "32px",
    position: "absolute",
    justifyContent: "space-between",

    [mainTheme.breakpoints.down("md")]: {
      marginBottom: "16px",
    },
  },

  imageContainer: {
    width: "100%",
    height: "100%",
    minHeight: "200px",
    overflow: "hidden",
    borderRadius: "8px",
    marginBottom: "16px",
    position: "relative",

    [mainTheme.breakpoints.down("sm")]: {
      minHeight: "190px",
    },
  },

  titleOfArticle: {
    fontWeight: 400,
    fontSize: "16px",
    maxHeight: "36px",
    WebkitLineClamp: 2,
    overflow: "hidden",
    lineHeight: "18px",
    fontStyle: "normal",
    display: "-webkit-box",
    textOverflow: "ellipsis",
    color: "content.primary",
    WebkitBoxOrient: "vertical",
  },

  site: {
    top: 10,
    left: 10,
    display: "flex",
    fontWeight: 400,
    fontSize: "12px",
    padding: "3px 6px",
    lineHeight: "16px",
    borderRadius: "4px",
    fontStyle: "normal",
    position: "absolute",
    letterSpacing: "0.2px",
    alignItems: "flex-start",
    color: "accent.brand.dark",
    backgroundColor: "accent.brand.lightest",
  },

  container: {
    overflow: "hidden",
    position: "relative",
    padding: "60px 16px 0",

    [mainTheme.breakpoints.up("md")]: {
      marginBottom: "110px",
    },

    [mainTheme.breakpoints.down("sm")]: {
      paddingBottom: "24px",
    },

    "& .mySwiper": {
      zIndex: 0,
      position: "static",

      "& .swiper-wrapper": {
        zIndex: 0,
      },

      "& .swiper-pagination": {
        display: "none",
      },

      "& .swiper-slide-custom": {
        "& .img-of-article": {
          transition: ".3s",
        },
        "&:hover .img-of-article": {
          transform: "scale(1.2)",
        },
        "&:hover .title-of-article": {
          color: "content.secondary",
        },
      },

      "& .swiper-button": {
        padding: 0,
        width: "32px",
        zIndex: "auto",
        height: "32px",
        display: "flex",
        minWidth: "20px",
        minHeight: "20px",
        borderRadius: "10px",
        alignItems: "center",
        justifyContent: "center",
        transition: "opacity .2s",
        backgroundColor: "secondary.main",

        "&:hover path": {
          stroke: COLORS.BLUE,
        },
      },

      [mainTheme.breakpoints.down("sm")]: {
        "& .swiper-pagination-bullets": {
          bottom: "-4px !important",
        },

        "& .swiper-pagination": {
          display: "block",
          pointerEvents: "none",
        },

        "& .swiper-pagination-bullet-active": {
          width: "14px",
          borderRadius: "100px",
          backgroundColor: "grey.light",
        },

        "& .swiper-pagination-bullet": {
          opacity: 1,
          backgroundColor: "grey.white",
          pointerEvents: "auto !important",
          transition: "width .3s, background-color .4s",
        },
      },
    },
  },
};
