"use client";
import Image from "next/image";
import { FC, useCallback } from "react";

import { Box, Typography, useMediaQuery } from "@mui/material";
import { useUnit } from "effector-react";
import { useTranslations } from "next-intl";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, useSwiper, SwiperSlide } from "swiper/react";

import { styles } from "./styles";

import { LIST_OF_ARTICLES } from "@/entities/massMediaAboutUs/lib/constants";
import type { TSwiperProps } from "@/entities/massMediaAboutUs/model/types";

import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import { Container, SliderButton } from "@/shared/ui";

const SwiperPrevButton = (props: TSwiperProps) => {
  const swiper = useSwiper();
  return (
    <SliderButton arrow="left" {...props} onClick={() => swiper.slidePrev()} />
  );
};
const SwiperNextButton = (props: TSwiperProps) => {
  const swiper = useSwiper();
  return (
    <SliderButton arrow="right" {...props} onClick={() => swiper.slideNext()} />
  );
};

export const MassMediaAboutUs: FC = () => {
  const t = useTranslations();
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  const handleArticleClick = useCallback((title: string, link: string) => {
    sendDataLayerEventFn({
      event: "massmediiaClick",
      data: {
        clickUrl: link,
        massmediaСard: title,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container sx={styles.container}>
      <Swiper
        loop
        className="mySwiper"
        spaceBetween={isPhone ? 10 : 24}
        modules={[Autoplay, Navigation, Pagination]}
        slidesPerView={isTablet ? (isPhone ? 1 : 2) : 4}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        <Box sx={styles.wrapper}>
          <Typography component="h2" sx={styles.title}>
            {t("ABOUT_US")}
          </Typography>

          <Box sx={styles.swiperButtons}>
            <SwiperPrevButton className="swiper-button swiper-button-prev" />
            <SwiperNextButton className="swiper-button swiper-button-next" />
          </Box>
        </Box>

        {LIST_OF_ARTICLES.map(
          ({ img, site, linkToArticle, titleOfArticle }, idx: number) => {
            return (
              <SwiperSlide key={idx} className="swiper-slide-custom">
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={styles.link}
                  href={linkToArticle}
                  onClick={() => {
                    handleArticleClick(titleOfArticle, linkToArticle);
                  }}
                >
                  <Box sx={styles.imageContainer}>
                    <Image
                      fill
                      src={img}
                      alt={site}
                      sizes="100vw"
                      className="img-of-article"
                      style={{ objectFit: "cover" }}
                    />
                    <Typography sx={styles.site}>{site}</Typography>
                  </Box>
                  <Typography
                    sx={styles.titleOfArticle}
                    className="title-of-article"
                  >
                    {t(titleOfArticle)}
                  </Typography>
                </a>
              </SwiperSlide>
            );
          },
        )}
      </Swiper>
    </Container>
  );
};
