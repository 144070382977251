/* eslint-disable perfectionist/sort-objects */
import { sample } from "effector";
import { createGate } from "effector-react";

import { AVAILABLE_LOCALES } from "@/shared/config/locales/config";
import { EBanners } from "@/shared/lib/constants";
import { getBannersQuery } from "@/shared/model/api/banners";

export const BannersGate = createGate<string>();

sample({
  clock: BannersGate.open,
  fn: (lang) =>
    lang === AVAILABLE_LOCALES[0] ? EBanners.Middle : EBanners.MiddleKz,
  target: getBannersQuery.refresh,
});
