import(/* webpackMode: "eager" */ "/app/entities/banner-carousel/ui/index.tsx");
import(/* webpackMode: "eager" */ "/app/entities/constructor/articles-list/ui/articles-list/index.tsx");
import(/* webpackMode: "eager" */ "/app/entities/constructor/promo-list/ui/promo-list/index.tsx");
import(/* webpackMode: "eager" */ "/app/entities/massMediaAboutUs/ui/MassMediaAboutUs.tsx");
import(/* webpackMode: "eager" */ "/app/entities/partners/OurPartners/ui/OurPartners.tsx");
import(/* webpackMode: "eager" */ "/app/entities/questionnaire/ui/QuestionnaireContainer/QuestionnaireContainer.tsx");
import(/* webpackMode: "eager" */ "/app/entities/questionnaire/ui/QuestionnaireModal/QuestionnaireModal.tsx");
import(/* webpackMode: "eager" */ "/app/entities/questionnaire/ui/SuccessModal/SuccessModal.tsx");
import(/* webpackMode: "eager" */ "/app/entities/tours/HotTours/HotTours.tsx");
import(/* webpackMode: "eager" */ "/app/entities/tours/TourCard/ui/TourCard/TourCard.tsx");
import(/* webpackMode: "eager" */ "/app/entities/tours/TourListLoader/TourListLoader.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@effector/next/dist/index.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/pagesLayer/home/ui/DownloadApp/DownloadApp.tsx");
import(/* webpackMode: "eager" */ "/app/public/icons/socials/facebook_colored.svg");
import(/* webpackMode: "eager" */ "/app/public/icons/socials/instagram_colored.svg");
import(/* webpackMode: "eager" */ "/app/public/icons/socials/telegram_colored.svg");
import(/* webpackMode: "eager" */ "/app/public/icons/socials/whatsapp_colored.svg");
import(/* webpackMode: "eager" */ "/app/shared/config/scripts/amplitude-init.ts");
import(/* webpackMode: "eager" */ "/app/shared/config/scripts/mindbox-scripts.tsx");
import(/* webpackMode: "eager" */ "/app/shared/ui/styles/theme/ThemeRegistry.tsx");
import(/* webpackMode: "eager" */ "/app/widgets/search/index.ts")