import { createQuery } from "@farfetched/core";

import { getBannersFx } from "./endpoints";

import { QueryTools } from "@/shared/lib/helpers/farfetched";

export const getBannersQuery = createQuery({
  effect: getBannersFx,
  name: "getBannersQuery",
  mapData: ({ result }) => result.data.data,
});

QueryTools(getBannersQuery).errors.handleAll();
