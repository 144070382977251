export const styles = {
  downloadAppContainer: {
    marginTop: "80px",
  },

  container: {
    paddingTop: "16px",
    marginBottom: "48px",
    paddingBottom: "16px",
    backgroundColor: "dominant.cloud",
  },

  appDownload: {
    display: "flex",
    columnGap: "8px",
    margin: "0 auto",
    maxWidth: "644px",
    alignItems: "center",
    justifyContent: "space-around",
  },
};
