import { attach } from "effector";

import { TBannerCarouselResponse } from "../../types/banners";
import { TFFRequest } from "../../types/common/entities/common";

import { requestFx } from "@/shared/config";

type TBannersResponse = TFFRequest<TBannerCarouselResponse[]>;

export const getBannersFx = attach<string, TBannersResponse>({
  effect: requestFx,
  mapParams: (code) => ({
    url: `banners/${code}`,
  }),
});
