"use client";
import { FC } from "react";

import { Box, Link, SxProps, Skeleton, useMediaQuery } from "@mui/material";
import { useGate, useUnit } from "effector-react";
import { useLocale } from "next-intl";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, useSwiper, SwiperSlide } from "swiper/react";

import { BannersGate } from "../lib/model";
import { styles } from "./styles";

import { Condition } from "@/shared/lib/condition";
import { useCustomTheme } from "@/shared/lib/hooks/useCustomTheme";
import { sendDataLayerEvent } from "@/shared/model/analytics";
import { getBannersQuery } from "@/shared/model/api/banners";
import { TBannerCarouselResponse } from "@/shared/model/types/banners";
import { Image, Container, SliderButton } from "@/shared/ui";

type TSwiperProps = {
  className: string;
};

const SwiperPrevButton = (props: TSwiperProps) => {
  const swiper = useSwiper();
  return (
    <SliderButton
      arrow="left"
      className={props.className}
      onClick={() => swiper.slidePrev()}
    />
  );
};
const SwiperNextButton = (props: TSwiperProps) => {
  const swiper = useSwiper();
  return (
    <SliderButton
      arrow="right"
      className={props.className}
      onClick={() => swiper.slideNext()}
    />
  );
};

export const BannerCarousel: FC = () => {
  const theme = useCustomTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const locale = useLocale();
  const sendDataLayerEventFn = useUnit(sendDataLayerEvent);

  useGate(BannersGate, locale);
  const { data: bannersList, pending: isLoading } = useUnit(getBannersQuery);

  if (isLoading) {
    return (
      <Container>
        <Skeleton variant="rounded" sx={styles.skeleton} />
      </Container>
    );
  }

  return (
    <Condition match={Boolean(bannersList && bannersList.length > 0)}>
      <Container sx={styles.carouselContainer}>
        <Box sx={styles.carousel}>
          <Box sx={styles.swiperContainer as SxProps}>
            <Swiper
              loop={true}
              slidesPerView={1}
              className="mySwiper"
              modules={[Autoplay, Pagination, Navigation]}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              {bannersList?.map(
                ({
                  name,
                  id = "",
                  link = "",
                  mobile_cover = "",
                  desktop_cover = "",
                }: TBannerCarouselResponse) => {
                  return (
                    <SwiperSlide key={id} style={styles.wrapper}>
                      <Link
                        href={link ? link : ""}
                        sx={styles.swiperContainer.swiperLink}
                        onClick={() =>
                          sendDataLayerEventFn({
                            data: { clickUrl: link },
                            event: "bannerTourClick",
                          })
                        }
                      >
                        <Image
                          alt={name}
                          quality={100}
                          src={isPhone ? mobile_cover : desktop_cover}
                        />
                      </Link>
                    </SwiperSlide>
                  );
                },
              )}
              <SwiperPrevButton className="swiper-button swiper-button-prev" />
              <SwiperNextButton className="swiper-button swiper-button-next" />
            </Swiper>
          </Box>
        </Box>
      </Container>
    </Condition>
  );
};
